export default function Card(props) {
  return (
    <>
      {props.story && props.background ? (
        <div className="h-auto w-[calc(100%-32px)] mx-[16px] backdrop-blur rounded-md text-left cursor-pointer">
          <a
            class="flex flex-col overflow-hidden h-auto w-full rounded-md bg-gray-900/70 shadow-md transition hover:bg-gray-800/70  hover:shadow-2xl hover:shadow-sky-500/40"
            href={!!props.link ? props.link : null}
            target="_blank"
            noreferrer
            noreopener
          >
            {!!props.image && (
              <div class="flex-shrink-0">
                <img
                  class="h-48 w-full object-cover"
                  src={props.image}
                  alt=""
                />
              </div>
            )}
            <div class="flex-1 bg-gray-900/70 p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium text-sky-300">
                  <span>{props.overline}</span>
                </p>
                <a
                  href={!!props.link ? props.link : null}
                  target="_blank"
                  noreferrer
                  noreopener
                  class="block mt-2"
                >
                  <p class="text-xl font-semibold text-gray-100">
                    {props.title}
                  </p>
                  <p class="mt-3 text-base text-gray-200">
                    {props.description}
                  </p>
                </a>
              </div>
            </div>
          </a>
        </div>
      ) : (
        <>
          {props.background ? (
            <div className="h-auto w-[calc(100%-32px)] mx-[16px] backdrop-blur rounded-md text-left cursor-pointer">
              <div className="h-auto w-full rounded-md bg-gray-900/70 p-8 shadow-md transition hover:bg-gray-800/70  hover:shadow-2xl hover:shadow-sky-500/40">
                <p className="text-xs uppercase tracking-widest text-sky-300">
                  {props.overline}
                </p>
                <h2 className="text-4xl font-medium text-gray-200">
                  {props.title}
                </h2>
                <p className="text-gray-100 font-normal">{props.description}</p>
                {props.link && (
                  <a
                    href={!!props.link ? props.link : null}
                    target="_blank"
                    noreferrer
                    noreopener
                  >
                    Read More
                  </a>
                )}
              </div>
            </div>
          ) : (
            <div className="h-auto w-full p-2 text-left cursor-pointer">
              <div className="h-auto w-full rounded-md border bg-white p-8 shadow-md transition hover:border-maroon-300 hover:shadow-2xl hover:shadow-maroon-500/20">
                <p className="text-xs uppercase tracking-widest text-maroon-500">
                  {props.overline}
                </p>
                <h2 className="text-4xl font-bold text-gray-700">
                  {props.title}
                </h2>
                <p className="text-gray-600">{props.description}</p>
                {props.link && (
                  <a
                    href={!!props.link ? props.link : null}
                    target="_blank"
                    noreferrer
                    noreopener
                  >
                    Read More
                  </a>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

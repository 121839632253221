import clsx from "clsx";

const styles =
  "inline-flex justify-center rounded bg-maroon-600 p-4 text-base font-semibold text-white hover:bg-maroon-500 active:text-white/70 focus:outline-none focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-maroon-500";

export function Button({ className, ...props }) {
  return <button className={clsx(styles, className)} {...props} />;
}

export function ButtonLink({ href, className, ...props }) {
  return <a className={clsx(styles, className)} herf={href} {...props} />;
}

import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useState } from "react";
import { SlideDown } from "./animations/SlideDown";

import { Container } from "./Container";

function MobileNavigation(props) {
  return (
    <Popover>
      {({ open, close }) => (
        <>
          <Popover.Button className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none">
            <span className="sr-only">Toggle Navigation</span>
            <svg
              aria-hidden="true"
              className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
              fill="none"
              strokeWidth={2}
              strokeLinecap="round"
            >
              <path
                d="M0 1H14M0 7H14M0 13H14"
                className={clsx("origin-center transition", {
                  "scale-90 opacity-0": open,
                })}
              />
              <path
                d="M2 2L12 12M12 2L2 12"
                className={clsx("origin-center transition", {
                  "scale-90 opacity-0": !open,
                })}
              />
            </svg>
          </Popover.Button>
          <Transition.Root>
            <Transition.Child
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="duration-150 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Overlay className="fixed inset-0 h-screen bg-indigo-50/90" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                as="ul"
                className="absolute inset-x-0 top-full mt-4 origin-top space-y-4 rounded-2xl bg-white p-6 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
              >
                {props.navigationLinks?.map((link) => (
                  <li key={link.title}>
                    <a
                      href={link.link}
                      className="block w-full"
                      onClick={() => close()}
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </Popover.Panel>
            </Transition.Child>
          </Transition.Root>
        </>
      )}
    </Popover>
  );
}

export function Header(props) {
  let [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <>
      <header className="top-0 z-50 bg-transparent py-4 absolute w-full">
        <SlideDown transition={{ delay: 1.5, duration: 0.5 }}>
          <Container>
            <nav className="relative z-50 text-sm">
              <ul className="flex items-center flex-row lg:flex-row justify-between lg:gap-4">
                <li className="flex-shrink">
                  <a href="#">
                    <span className="sr-only">Home</span>

                    <img
                      src="https://www.svvsd.org/wp-content/uploads/2020/10/SVVSD-full-logo-white.png"
                      width={240}
                      height={58}
                      priority
                      unoptimized
                    />
                  </a>
                </li>
                <div className="ml-12 hidden gap-10 lg:flex">
                  {props.navigationLinks?.map((link, index) => (
                    <a
                      key={link.title}
                      href={link.link}
                      className=" relative -my-2 -mx-3 rounded-lg px-3 py-2 text-sm text-gray-300 transition-colors delay-150 hover:text-gray-100 hover:delay-[0ms]"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <AnimatePresence>
                        {hoveredIndex === index && (
                          <motion.span
                            className="absolute inset-0 rounded-lg bg-gray-700"
                            layoutId="hoverBackground"
                            initial={{ opacity: 0 }}
                            animate={{
                              opacity: 1,
                              transition: { duration: 0.15 },
                            }}
                            exit={{
                              opacity: 0,
                              transition: { duration: 0.15, delay: 0.2 },
                            }}
                          />
                        )}
                      </AnimatePresence>
                      <span className="relative z-10">{link.title}</span>
                    </a>
                  ))}
                </div>
                <li className="ml-auto -mr-1 lg:hidden">
                  <MobileNavigation navigationLinks={props.navigationLinks} />
                </li>
              </ul>
            </nav>
          </Container>
        </SlideDown>
      </header>
    </>
  );
}

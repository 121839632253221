import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { SlideUp } from "./animations/SlideUp";
import Card from "./Card";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder(sanityClient);

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:
function urlFor(source) {
  return builder.image(source);
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1920 },
    items: 5,
  },
  largeDesktop: {
    breakpoint: { max: 1920, min: 1440 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

const responsiveStories = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1920 },
    items: 4,
  },
  largeDesktop: {
    breakpoint: { max: 1920, min: 1440 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1280, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

// Whitelist classnames
// bg-green-600 bg-maroon-600 bg-navy-600

export function CardCarousel(props) {
  console.log(props.cards);
  return (
    <>
      {props.cards && (
        <SlideUp
          className={`relative ${
            props.background
              ? "bg-gradient-to-tr from-navy-900 to-gray-800 lg:py-16"
              : ""
          }`}
        >
          {props.background && props.image && (
            <img
              src={props.image}
              className=" absolute inset-0 object-cover opacity-50 w-full h-full"
            />
          )}

          <Carousel
            responsive={props.story ? responsiveStories : responsive}
            focusOnSelect
            arrows={false}
            infinite
            autoPlay
            autoPlaySpeed={8000}
            swipeable
            draggable
            sliderClass="p-0 my-16"
            customTransition=".5s ease-in-out"
          >
            {props.cards.map((card) => (
              <Card
                key={card._key}
                overline={card.overline}
                title={card.title}
                description={card.description}
                background={props.background}
                story={props.story}
                image={
                  card.storyImage
                    ? urlFor(card.storyImage).width(800).url()
                    : ""
                }
                link={card.link}
              />
            ))}
          </Carousel>
        </SlideUp>
      )}
    </>
  );
}

import { SlideUp } from "./animations/SlideUp";

/**
 *
 * @param {{title: string, name: string, email: string, phone: string}} props
 */

export function ContactUser(props) {
  return (
    <SlideUp>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        {props.name}
      </h3>
      {props.title && (
        <p className="text-sm font-bold text-maroon-500">
          <span className="sr-only">Title</span>
          {props.title}
        </p>
      )}
      <dl className="mt-2 text-base text-gray-500">
        {props.email && (
          <div className="mt-1 flex items-center justify-center sm:justify-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            <div className="ml-2">
              <dt className="sr-only">Email</dt>
              <dd>{props.email}</dd>
            </div>
          </div>
        )}

        {props.phone && (
          <div className="mt-1 flex items-center justify-center sm:justify-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
            <div className="ml-2">
              <dt className="sr-only">Phone number</dt>
              <dd>{props.phone}</dd>
            </div>
          </div>
        )}
      </dl>
    </SlideUp>
  );
}

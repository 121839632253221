import clsx from 'clsx';

export function PhoneFrame({
  className,
  children,
  priority = false,
  ...props
}) {
  return (
    <div className={clsx('relative h-[556px] w-[556px]', className)} {...props}>
      <div className="absolute inset-0 grid  h-[556px] w-[556px] transform grid-cols-1 overflow-hidden  rounded-full md:z-10">
        {children}
      </div>
    </div>
  );
}

import { motion } from "framer-motion";
import { SlideUp } from "./animations/SlideUp";

export function Testimonial(props) {
  return (
    <>
      {!props.alternate ? (
        <div className="bg-white py-16 lg:py-24">
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <SlideUp
              className={`relative py-24 px-8 ${props.color} overflow-hidden rounded-xl shadow-2xl lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-16`}
            >
              <div className="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter">
                <img
                  src={props.image}
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="relative lg:col-span-1">
                <blockquote
                  className="mt-6 text-white"
                  initial={{ opacity: 0, translateX: -100 }}
                  whileInView={{ opacity: 1, translateX: 0 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.65,
                    staggerChildren: 1,
                  }}
                >
                  <p className="text-xl font-medium sm:text-2xl">
                    &ldquo;{props.description}&rdquo;
                  </p>
                  <footer className="mt-6">
                    <p className="flex flex-col font-medium">
                      <span>{props.name}</span>
                      <span>{props.title}</span>
                    </p>
                  </footer>
                </blockquote>
              </div>
            </SlideUp>
          </div>
        </div>
      ) : (
        <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative">
              <blockquote className="mt-10">
                <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                  <p>&ldquo;{props.description}&rdquo;</p>
                </div>
                <footer className="mt-8">
                  <div className="md:flex md:items-center md:justify-center">
                    <div className="md:flex-shrink-0">
                      <img
                        className="mx-auto h-10 w-10 rounded-full object-cover"
                        src={props.image}
                        alt=""
                      />
                    </div>
                    <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                      <div className="text-base font-medium text-gray-900">
                        {props.name}
                      </div>

                      <svg
                        className="hidden md:block mx-1 h-5 w-5 text-maroon-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M11 0h3L9 20H6l5-20z" />
                      </svg>

                      <div className="text-base font-medium text-gray-500">
                        {props.title}
                      </div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

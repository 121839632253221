import React from "react";
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import Homepage from "./pages/homepage";
import { WhitePaper } from "./pages/whitepaper";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/whitepaper" element={<WhitePaper />} />
          <Route path="/:slug" element={<Homepage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import { Header } from "../components/Header";
import { WhitePaperHero } from "../components/WhitePaperHero";
import sanityClient from "../client";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export function WhitePaper() {
  const [navData, setNavData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHomepage();
  }, []);

  function getHomepage() {
    sanityClient
      .fetch(
        /* js */ `
      {
        'navigation': *[_type == "navigation"][0]{
          navigationLinks,
        }
      }
      `
      )
      .then((data) => {
        setNavData(data.navigation);
        setLoading(false);
      })
      .finally()
      .catch(console.error);
  }

  return (
    <>
      <Link
        className="bg-maroon-800 w-10 h-10 absolute top-0 left-0 m-8 rounded-full z-50 flex items-center justify-center shadow-lg text-maroon-300 hover:bg-maroon-900 transition-colors"
        to="/"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-5 h-5"
        >
          <path
            fill-rule="evenodd"
            d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
            clip-rule="evenodd"
          />
        </svg>
      </Link>
      <WhitePaperHero />
    </>
  );
}

import clsx from "clsx";
import { useInView } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";

import sanityClient from "../client";

import { Container } from "./Container";

import imageUrlBuilder from "@sanity/image-url";
import { SlideUp } from "./animations/SlideUp";

// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder(sanityClient);

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:
function urlFor(source) {
  return builder.image(source);
}

function Review({ title, description, byline, logo, className, ...props }) {
  return (
    <figure
      className={clsx(
        "rounded-lg bg-white p-6 shadow-2xl shadow-gray-700/20 ",
        className
      )}
      {...props}
    >
      {logo && (
        <img
          src={urlFor(logo).width(400).url()}
          className="h-12 w-auto max-w-[100px] rounded-md object-contain"
        />
      )}

      <blockquote className="text-gray-900">
        <p className="mt-4 text-lg font-semibold leading-6 before:content-['“'] after:content-['”']">
          {title}
        </p>
        <p className="mt-3 text-base leading-7">{description}</p>
      </blockquote>
      <figcaption className="mt-3 text-sm text-gray-600 before:content-['–_']">
        {byline}
      </figcaption>
    </figure>
  );
}

function splitArray(array, numParts) {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts;
    if (!result[index]) {
      result[index] = [];
    }
    result[index].push(array[i]);
  }
  return result;
}

function ReviewColumn({
  className,
  reviews,
  reviewclassName = () => {},
  msPerPixel = 0,
}) {
  let columnRef = useRef();
  let [columnHeight, setColumnHeight] = useState(0);
  let duration = `${columnHeight * msPerPixel}ms`;

  useEffect(() => {
    let resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current.offsetHeight);
    });

    resizeObserver.observe(columnRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={columnRef}
      className={clsx("animate-marquee space-y-8 py-4", className)}
      style={{ "--marquee-duration": duration }}
    >
      {reviews.concat(reviews).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewclassName(reviewIndex % reviews.length)}
          {...review}
        />
      ))}
    </div>
  );
}

function ReviewGrid({ quotes, ...props }) {
  let containerRef = useRef();
  let columns = splitArray(quotes, 3);
  columns = [columns[0], columns[1], splitArray(columns[2], 2)];

  return (
    <div
      ref={containerRef}
      className="relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-y-hidden overflow-x-visible px-4 sm:mt-20 md:grid-cols-2 lg:-mx-16 lg:grid-cols-3 lg:px-16"
    >
      <>
        <ReviewColumn
          reviews={[...columns[0], ...columns[2].flat(), ...columns[1]]}
          reviewclassName={(reviewIndex) =>
            clsx(
              reviewIndex >= columns[0].length + columns[2][0].length &&
                "md:hidden",
              reviewIndex >= columns[0].length && "lg:hidden"
            )
          }
          msPerPixel={10}
        />
        <ReviewColumn
          reviews={[...columns[1], ...columns[2][1]]}
          className="hidden md:block"
          reviewclassName={(reviewIndex) =>
            reviewIndex >= columns[1].length && "lg:hidden"
          }
          msPerPixel={15}
        />
        <ReviewColumn
          reviews={columns[2].flat()}
          className="hidden lg:block"
          msPerPixel={10}
        />
      </>

      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-50" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-50" />
    </div>
  );
}

export function Reviews({ title, description, quotes, ...props }) {
  return (
    <section
      id="reviews"
      aria-labelledby="reviews-title"
      className="bg-gray-50 pt-20 pb-16 sm:pt-32 sm:pb-24"
    >
      <Container>
        <SlideUp>
          <h2
            id="reviews-title"
            className="text-3xl font-medium tracking-tight text-gray-900 sm:text-center"
          >
            {title}
          </h2>
        </SlideUp>
        <SlideUp>
          <p className="mx-auto mt-2 max-w-5xl text-lg text-gray-600 sm:text-center">
            {description}
          </p>
        </SlideUp>
        {quotes && (
          <SlideUp>
            <ReviewGrid quotes={quotes} />
          </SlideUp>
        )}
      </Container>
    </section>
  );
}

import { SlideUp } from "./animations/SlideUp";
import { ContactUser } from "./ContactUser";

/**
 *
 * @param {{title: string}} props
 */

export function Contact(props) {
  console.log(props.contactUsers);
  return (
    <div className="bg-gray-50 text-center sm:text-left">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="divide-y-2 divide-gray-200">
          <SlideUp>
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                {props.title}
              </h2>
              {props.contactUsers && (
                <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:col-span-2 lg:mt-0">
                  {props.contactUsers.map((contactUser) => (
                    <ContactUser
                      name={contactUser.name}
                      title={contactUser.title}
                      phone={contactUser.phone}
                      email={contactUser.email}
                    />
                  ))}
                </div>
              )}
            </div>
          </SlideUp>
        </div>
      </div>
    </div>
  );
}

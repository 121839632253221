import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useEffect, useId, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { AppScreen } from "./AppScreen";
import { Container } from "./Container";
import { PhoneFrame } from "./PhoneFrame";
import { CircleBackground } from "./CircleBackground";

import ForkRightIcon from "@mui/icons-material/ForkRight";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SchoolIcon from "@mui/icons-material/School";

import Card from "./Card";
import { SlideRight } from "./animations/SlideRight";
import { FadeIn } from "./animations/FadeIn";

const MotionAppScreenHeader = motion(AppScreen.Header);
const MotionAppScreenBody = motion(AppScreen.Body);



// const features = [
//   {
//     name: "Rigorous Academics",
//     description:
//       "For years St. Vrain has successfully cultivated academic focus programs and career pathways such as International Baccalaureate (IB), Pre-Collegiate Advanced Placement (AP), and Math and Science within existing schools.",
//     icon: SchoolIcon,
//     screen: InviteScreen,
//   },
//   {
//     name: "Workforce Pathways",
//     description:
//       "St. Vrain also offers the state’s largest suite of P-TECH programs (Computer Information Systems, Cybersecurity, and Biochemistry), which provide an associate degree and pathway to employment with an industry partner at no-cost to the student.",
//     icon: ForkRightIcon,
//     screen: StocksScreen,
//   },
//   {
//     name: "Strong Partnerships",
//     description:
//       "St. Vrain is grateful for the meaningful contributions of our corporate partners, who greatly enhance the opportunities available to our students. Industry leaders serve in many capacities that serve to advance the future workforce.",
//     icon: HandshakeIcon,
//     screen: InvestScreen,
//   },
// ];

const headerAnimation = {
  initial: { opacity: 0, transition: { duration: 0.3 } },
  animate: { opacity: 1, transition: { duration: 0.3, delay: 0.3 } },
  exit: { opacity: 0, transition: { duration: 0.3 } },
};

const maxZIndex = 2147483647;

const bodyVariantBackwards = {
  opacity: 0,
  scale: 0.8,
  zIndex: 0,
  filter: "blur(4px)",
  zIndex: 0,
  transition: { duration: 0.4 },
};

const bodyVariantForwards = (custom) => ({
  y: "110%",
  zIndex: maxZIndex - custom.changeCount,
  transition: { duration: 0.4 },
});

const bodyAnimation = {
  initial: "initial",
  animate: "animate",
  exit: "exit",
  variants: {
    initial: (custom) =>
      custom.isForwards ? bodyVariantForwards(custom) : bodyVariantBackwards,
    animate: (custom) => ({
      y: "0%",
      opacity: 1,
      scale: 1,
      zIndex: maxZIndex / 2 - custom.changeCount,
      filter: "blur(0px)",
      transition: { duration: 0.4 },
    }),
    exit: (custom) =>
      custom.isForwards ? bodyVariantBackwards : bodyVariantForwards(custom),
  },
};

function InviteScreen({ custom, animated = false }) {
  return (
    <AppScreen className="w-full">
      <MotionAppScreenBody {...(animated ? { ...bodyAnimation, custom } : {})}>
        <img
          src="https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png"
          className="h-full w-full object-cover invert"
        />
      </MotionAppScreenBody>
    </AppScreen>
  );
}

function StocksScreen({ custom, animated = false }) {
  return (
    <AppScreen className="w-full">
      <MotionAppScreenBody {...(animated ? { ...bodyAnimation, custom } : {})}>
        <img
          src="https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png"
          className="h-full w-full object-cover invert"
        />
      </MotionAppScreenBody>
    </AppScreen>
  );
}

function InvestScreen({ custom, animated = false }) {
  return (
    <AppScreen className="w-full">
      <MotionAppScreenBody {...(animated ? { ...bodyAnimation, custom } : {})}>
        <img
          src="https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png"
          className="h-full w-full object-cover invert"
        />
      </MotionAppScreenBody>
    </AppScreen>
  );
}

function usePrevious(value) {
  let ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FeaturesDesktop(features, ...props) {
  let [changeCount, setChangeCount] = useState(0);
  let [selectedIndex, setSelectedIndex] = useState(0);
  let prevIndex = usePrevious(selectedIndex);
  let isForwards = prevIndex === undefined ? true : selectedIndex > prevIndex;
  let onChange = useDebouncedCallback(
    (selectedIndex) => {
      setSelectedIndex(selectedIndex);
      setChangeCount((changeCount) => changeCount + 1);
    },
    100,
    { leading: true }
  );

  return (
    <Tab.Group
      as="div"
      className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24"
      selectedIndex={selectedIndex}
      onChange={onChange}
      vertical
    >
      <Tab.List className="relative z-10 order-last col-span-6 space-y-6">
        {features.map((feature, featureIndex) => (
          <FadeIn
            key={feature._key}
            className="relative rounded-2xl transition-colors hover:bg-gray-800/30"
          >
            {featureIndex === selectedIndex && (
              <motion.div
                layoutId="activeBackground"
                className="absolute inset-0 bg-gray-800"
                initial={{ borderRadius: 16 }}
              />
            )}
            <div className="relative z-10 p-8">
              <SlideRight className="relative">
                <feature.icon className="rotate-15 absolute -left-4 -top-4 z-10 h-16 w-16 text-gray-700/50 blur-[1px]" />
                <feature.icon className="relative z-10 h-8 w-8 text-gray-400" />
              </SlideRight>
              <h3 className="mt-6 text-lg font-semibold text-white">
                <Tab className="text-left outline-none">
                  <span className="absolute inset-0 rounded-2xl" />
                  {feature.name}
                </Tab>
              </h3>
              <p className="mt-2 text-sm text-gray-400">
                {feature.description}
              </p>
            </div>
          </FadeIn>
        ))}
      </Tab.List>
      <div className="relative col-span-6">
        {}
        <div className="absolute top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2">
          <CircleBackground color="#13B5C8" className="animate-spin-slower" />
        </div>
        <PhoneFrame className="mx-auto">
          <Tab.Panels as={Fragment}>
            <AnimatePresence
              initial={false}
              custom={{ isForwards, changeCount }}
            >
              {features.map((feature, featureIndex) =>
                selectedIndex === featureIndex ? (
                  <Tab.Panel
                    static
                    key={feature.name + changeCount}
                    className="col-start-1 row-start-1 flex focus:outline-offset-[32px] [&:not(:focus-visible)]:focus:outline-none"
                  >
                    <feature.screen
                      animated
                      custom={{ isForwards, changeCount }}
                    />
                  </Tab.Panel>
                ) : null
              )}
            </AnimatePresence>
          </Tab.Panels>
        </PhoneFrame>
      </div>
    </Tab.Group>
  );
}

function FeaturesMobile({features}) {
  let [activeIndex, setActiveIndex] = useState(0);
  let slideContainerRef = useRef();
  let slideRefs = useRef([]);

  useEffect(() => {
    let observer = new window.IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            setActiveIndex(slideRefs.current.indexOf(entry.target));
            break;
          }
        }
      },
      {
        root: slideContainerRef.current,
        threshold: 0.6,
      }
    );

    for (let slide of slideRefs.current) {
      if (slide) {
        observer.observe(slide);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [slideContainerRef, slideRefs]);

  return (
    <>
      <div
        ref={slideContainerRef}
        className="-mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 [&::-webkit-scrollbar]:hidden"
      >
        {features.map((feature, featureIndex) => (
          <div
            key={featureIndex}
            ref={(ref) => (slideRefs.current[featureIndex] = ref)}
            className="w-full flex-none snap-center px-4 sm:px-6"
          >
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
              <PhoneFrame className="relative mx-auto w-full">
                <feature.screen />
              </PhoneFrame>
              <div className="absolute inset-x-0 bottom-0 bg-gray-800/95 p-6 backdrop-blur sm:p-10">
                <feature.icon className="h-8 w-8" />
                <h3 className="mt-6 text-sm font-semibold text-white sm:text-lg">
                  {feature.name}
                </h3>
                <p className="mt-2 text-sm text-gray-400">
                  {feature.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-center gap-3">
        {features.map((_, featureIndex) => (
          <button
            type="button"
            key={featureIndex}
            className={clsx(
              "relative h-0.5 w-4 rounded-full focus:outline-none",
              featureIndex === activeIndex ? "bg-gray-300" : "bg-gray-500"
            )}
            aria-label={`Go to slide ${featureIndex + 1}`}
            onClick={() => {
              slideRefs.current[featureIndex].scrollIntoView({
                block: "nearest",
                inline: "nearest",
              });
            }}
          >
            <span className="absolute -inset-x-1.5 -inset-y-3" />
          </button>
        ))}
      </div>
    </>
  );
}

export function PrimaryFeatures2({ title, description, tabs, ...props }) {
  return (
    <section
      id="features"
      aria-label="Features for investing all your money"
      className="relative bg-gradient-to-tr from-navy-900 to-gray-800 py-20 sm:py-32"
    >
      <div className="absolute inset-0 h-full w-full opacity-100 mix-blend-overlay grayscale overflow-hidden">
        <img
          src={"https://www.svvsd.org/wp-content/uploads/2020/08/BlueGlobe.jpg"}
          alt=""
          layout="fixed"
          unoptimized
          className="h-full w-full scale-110 transform object-cover"
        />
      </div>
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
          <SlideRight>
            <h2 className=" text-3xl font-medium tracking-tight text-white">
              {title}
            </h2>
          </SlideRight>
          <SlideRight>
            <p className="mt-2 text-lg text-gray-300">{description}</p>
          </SlideRight>
        </div>
      </Container>
      {tabs && (
        <div className="mt-16 md:hidden">
          <FeaturesMobile features={tabs} />
        </div>
      )}

      {tabs && (
        <Container className="hidden md:mt-20 md:block">
          <FeaturesDesktop features={tabs} />
        </Container>
      )}
    </section>
  );
}

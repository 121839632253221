// `components` object you'll pass to PortableText w/ optional TS definition
import { PortableText } from "@portabletext/react";
import { AccordionSection } from "./Accordion";
import { SlideRight } from "./animations/SlideRight";
import { SlideUp } from "./animations/SlideUp";

const customComponents = {
  marks: {
    // Ex. 1: custom renderer for the em / italics decorator
    em: ({ children }) => (
      <em className="font-semibold text-gray-600">{children}</em>
    ),

    // Ex. 2: rendering a custom `link` annotation
    link: ({ value, children }) => {
      const target = (value?.href || "").startsWith("http")
        ? "_blank"
        : undefined;
      return (
        <a
          className="text-red-500"
          href={value?.href}
          target={target}
          rel={target === "_blank" && "noindex nofollow"}
        >
          {children}
        </a>
      );
    },
    types: {
      button: (props) => <span>{JSON.stringify(props, null, 2)}</span>,
    },
  },
  block: {
    h2: ({ children }) => (
      <h2 className="mt-1 font-sans text-4xl font-extrabold leading-normal text-gray-900 sm:text-5xl sm:tracking-tight">
        {children}
      </h2>
    ),
    h3: ({ children }) => (
      <h3 className="mt-1 font-sans text-2xl font-bold leading-normal text-gray-900 sm:text-3xl sm:tracking-tight">
        {children}
      </h3>
    ),
  },
  button: ({ value, children }) => (
    <h3 className="mt-1 font-sans text-2xl font-bold leading-normal text-gray-900 sm:text-3xl sm:tracking-tight">
      {value.label} {children}
    </h3>
  ),
};

export function ContentSection(props) {
  return (
    <div className="relative overflow-hidden pt-16 pb-16">
      <div className="relative">
        <div
          className={`lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8 ${
            !props.image &&
            !props.video &&
            !props.accordions &&
            "lg:grid-cols-1 justify-center"
          }`}
        >
          <div
            className={`mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0 ${
              !props.image &&
              !props.video &&
              !props.accordions &&
              "flex text-center justify-center"
            }`}
          >
            <SlideUp className="prose">
              <PortableText
                value={props.portableText}
                components={customComponents}
              />
            </SlideUp>
            {props.quote && (
              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">
                      &ldquo;{props.quote}&rdquo;
                    </p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="text-base font-medium text-gray-700">
                        {props.byline}
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            )}
          </div>
          {props.video && (
            <SlideRight
              transition={{ delay: 0.75, duration: 0.5 }}
              className="mt-12 sm:mt-16 lg:mt-0"
            >
              <div className=" pl-4 sm:pl-6 lg:relative lg:m-0 lg:h-full lg:px-0 flex items-center justify-center">
                <iframe
                  src={`https://player.vimeo.com/video/${props.video}`}
                  width="100%"
                  className="aspect-[16/9] rounded overflow-hidden shadow-lg"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                />
              </div>
            </SlideRight>
          )}
          {!props.video && props.image && (
            <SlideRight
              transition={{ delay: 0.75, duration: 0.5 }}
              className="mt-12 sm:mt-16 lg:mt-0"
            >
              <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={props.image}
                  alt="Inbox user interface"
                />
              </div>
            </SlideRight>
          )}
          {props.accordions && !props.video && !props.image && (
            <SlideRight className="lg:py-16 mx-auto max-w-lg w-full">
              <AccordionSection accordions={props.accordions} />
            </SlideRight>
          )}
        </div>
      </div>
    </div>
  );
}

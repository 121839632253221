import { Button, ButtonLink } from "../components/Button";
import { GridPattern } from "../components/GridPattern";
import coverImage from "../images/cover.png";
import { SlideRight } from "./animations/SlideRight";
import { SlideUp } from "./animations/SlideUp";

export function WhitePaperHero() {
  return (
    <header className="overflow-hidden bg-slate-100 lg:bg-transparent lg:px-5">
      <div className="mx-auto grid max-w-6xl grid-cols-1 grid-rows-[auto_1fr] gap-y-16 pt-16 md:pt-20 lg:grid-cols-12 lg:gap-y-20 lg:px-3 lg:pb-36 lg:pt-20 xl:py-32">
        <div className="relative flex items-end lg:col-span-5 lg:row-span-2">
          <div className="absolute -top-20 -bottom-12 left-0 right-1/2 z-10 rounded-br-6xl bg-[#862633] text-white/10 md:bottom-8 lg:-inset-y-32 lg:right-full lg:left-[-100vw] lg:-mr-40">
            <GridPattern
              x="100%"
              y="100%"
              patternTransform="translate(112 64)"
            />
          </div>
          <SlideUp className="relative z-10 mx-auto flex w-64 overflow-hidden rounded-lg bg-white shadow-2xl md:w-80 lg:w-auto">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-slate-300 to-transparent mix-blend-multiply"></div>
            <div className="absolute inset-0 bg-gradient-to-bl from-slate-100/90 via-transparent to-transparent mix-blend-screen"></div>
            <img
              className="w-full opacity-100"
              src={coverImage}
              alt=""
              priority
            />
          </SlideUp>
        </div>
        <div className="relative px-4 sm:px-6 lg:col-span-7 lg:pr-0 lg:pb-14 lg:pl-16 xl:pl-20 mt-12 lg:mt-0">
          <div className="hidden lg:absolute lg:bottom-0 lg:-top-32 lg:right-[-100vw] lg:left-[-100vw] lg:block lg:bg-slate-100" />
          <SlideRight className="relative z-10 text-center lg:text-left">
            <h1 className="font-display text-4xl font-extrabold text-slate-900 sm:text-5xl uppercase">
              The Future is in Our Public Schools
            </h1>
          </SlideRight>
        </div>
        <div className="bg-white pt-16 lg:col-span-7 lg:bg-transparent lg:pt-0 lg:pl-16 xl:pl-20">
          <SlideRight className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:px-0">
            <p className="text-2xl leading-7 font-bold text-slate-600">
              Envisioning A Stronger Public Education System For Colorado And
              Beyond
            </p>
            <p className="text-gray-700 mt-4 text-sm leading-loose font-serif">
              Our public schools have long been a catalyst for progress, having
              an immeasurable impact on the strength and advancement of our
              nation. Our educators should be celebrated and revered for the
              amazing things they do every day to advance our children as
              individuals, and our country as a whole. Public school graduates
              become our nation’s future doctors, service industry workers,
              first responders, engineers, teachers, scientists, and leaders who
              provide the backbone of our economy, national security, service
              industry, public health and safety, research innovations, and so
              much more.{" "}
            </p>
            <SlideUp className="my-8 flex gap-4">
              <a
                href="https://issuu.com/svvsd/docs/whitepaper-futureofcolorado"
                target="_blank"
                noreferrer
                noreopener
                className="inline-flex justify-center rounded bg-maroon-600 p-4 text-base font-semibold text-white hover:bg-maroon-500 active:text-white/70 focus:outline-none focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-maroon-500"
              >
                Read the White Paper
              </a>
            </SlideUp>
          </SlideRight>
        </div>
      </div>
    </header>
  );
}

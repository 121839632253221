import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronRight } from "@mui/icons-material";

const Accordion = ({ i, expanded, setExpanded, title, description }) => {
  const isOpen = i === expanded;

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>
      <motion.header
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
        className="p-4 font-semibold z-10 relative cursor-pointer bg-navy-50 hover:bg-navy-100 text-navy-700 flex items-center justify-between transition mt-2 rounded select-none"
      >
        {title}
        <ChevronRight
          className={`transition transform ${
            isOpen ? "rotate-90" : "rotate-0"
          }`}
        />
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto", scale: 1 },
              collapsed: { opacity: 0, height: 0, scale: 0.8 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="p-4">{description}</div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export const AccordionSection = (props) => {
  console.log(props);
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState(0);

  return (
    <div className="mb-4 overflow-hidden">
      {props.accordions && (
        <>
          {props.accordions.map((i, idx) => (
            <Accordion
              i={idx}
              expanded={expanded}
              setExpanded={setExpanded}
              title={i.title}
              description={i.description}
            />
          ))}
        </>
      )}
    </div>
  );
};

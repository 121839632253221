import { motion } from "framer-motion";

export function SlideDown(props) {
  return (
    <motion.div
      initial={{ opacity: 0, translateY: "-20px" }}
      whileInView={{ opacity: 1, translateY: "0px" }}
      transition={{ delay: 0.3, duration: 0.5 }}
      viewport={{ once: true }}
      {...props}
    >
      {props.children}
    </motion.div>
  );
}

import { Hero } from "../components/Hero";
import { Header } from "../components/Header2";
import { PrimaryFeatures2 } from "../components/PrimaryFeatures2";
import { Reviews } from "../components/Reviews";
import { Contact } from "../components/Contact";
import { useState, useEffect } from "react";
import { ContentSection } from "../components/ContentSection";
import sanityClient, { previewClient } from "../client";
import imageUrlBuilder from "@sanity/image-url";
import { Testimonial } from "../components/Testimonial";
import { CardCarousel } from "../components/CardCarousel";
import { AccordionSection } from "../components/Accordion";
import { useParams, useNavigate } from "react-router-dom";


function Homepage() {
  
  // if slug is undefined, set it to "home" as a fallback
  const { slug = "home" } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const [preview, setPreview] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [navData, setNavData] = useState(null);

  let queryParams;
  if (typeof window !== "undefined") {
    queryParams = new URLSearchParams(window.location.search);
  }

  function getStory() {
    sanityClient
      .fetch(
        /* js */ `
        *[_type == "story"][0]{
          title,
          description,
          image,   
          overline,
          link,
        }`
      )
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(console.error);
    setType("story");
  }

  function getHomepage() {
    sanityClient
    .fetch(
      /* js */ `
      {
        'homepage': *[_type == "homepage" && slug.current == $slug][0]{
          contentSection
        },
        'navigation': *[_type == "navigation"][0]{
          navigationLinks,
        }
      }
      `, { slug }
      )
      .then((data) => {
        if (Object.keys(data.homepage).length === 0) {
          navigate("/");  // Redirects to the homepage
          // refresh the page to get the latest data
          window.location.reload();
          return;
        }
        setPageData(data.homepage);
        setNavData(data.navigation);
        setLoading(false);
      })
      .finally()
      .catch(console.error);
    setType("homepage");
  }

  function watchHomepage() {
    previewClient
      .listen(
        /* js */ ` 
        *[_type == "homepage" && slug.current == $slug][0]{
          contentSection
        }
        `, { slug }
      )
      .subscribe((update) => {
        setPageData(update.result);
      });
  }

  useEffect(() => {
    setPreview(queryParams.get("livepreview"));

    switch (queryParams.get("type")) {
      case "story":
        getStory();
        break;
      default:
        getHomepage();
        break;
    }

    if (
      queryParams.get("livepreview") &&
      queryParams.get("type") == "homepage"
    ) {
      watchHomepage();
    }
  }, []);

  // Get a pre-configured url-builder from your sanity client
  const builder = imageUrlBuilder(sanityClient);

  // Then we like to make a simple function like this that gives the
  // builder an image and returns the builder for you to specify additional
  // parameters:
  function urlFor(source) {
    return builder.image(source);
  }

  return (
    <>
      {!!pageData && (
        <>
          {type == "homepage" && (
            <>
              <Header navigationLinks={navData?.navigationLinks} />
              {/* <div className="absolute z-50 h-48 w-full bg-gradient-to-b from-gray-900" /> */}
              <main className="overflow-x-hidden">
                {pageData?.contentSection?.map((content) => (
                  <div key={content._id}>
                    {content._type == "hero" && (
                      <Hero
                        title={content.title}
                        subtitle={content.subtitle}
                        description={content.description}
                        image={
                          content.image
                            ? urlFor(content.image).width(1920).url()
                            : ""
                        }
                        video={content.video}
                      />
                    )}
                    {content._type == "content" && (
                      <ContentSection
                        title={content.title}
                        description={content.description}
                        quote={content.quote}
                        image={
                          content.image
                            ? urlFor(content.image).width(1920).url()
                            : ""
                        }
                        portableText={content.wysiwyg}
                        video={content.video}
                        accordions={content.accordions}
                      />
                    )}
                    {content._type == "partnerSection" && (
                      <Reviews
                        title={content.title}
                        description={content.description}
                        quotes={content.partnerQuotes}
                      />
                    )}
                    {content._type == "primaryFeature" && (
                      <PrimaryFeatures2
                        title={content.title}
                        description={content.description}
                        tabs={content.primaryFeatureSection}
                      />
                    )}
                    {content._type == "cardCarousel" && (
                      <CardCarousel
                        title={content.title}
                        description={content.description}
                        cards={content.cards}
                        background={content.background}
                        image={
                          content.image
                            ? urlFor(content.image).width(1920).url()
                            : ""
                        }
                        story={content.story}
                      />
                    )}
                    {content._type == "testimonial" && (
                      <Testimonial
                        name={content.bylineFirstLine}
                        title={content.bylineSecondLine}
                        description={content.quote}
                        color="bg-navy-600"
                        image={
                          content.image
                            ? urlFor(content.image).width(1920).url()
                            : ""
                        }
                        alternate={content.alternate}
                      />
                    )}
                    {content._type == "contact" && (
                      <Contact
                        title={content.title}
                        contactUsers={content.contactUser}
                      />
                    )}
                    {content._type == "accordions" && (
                      <AccordionSection
                        accordions={content.accordions}
                      ></AccordionSection>
                    )}
                    {content._type == "primaryFeature" && (
                      <PrimaryFeatures2
                      title={content.title}
                      description={content.description}
                      tabs={content.primaryFeatureSection}
                      ></PrimaryFeatures2>
                    )}
                  </div>
                ))}
              </main>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Homepage;

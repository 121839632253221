import { SlideUp } from "./animations/SlideUp";
import { FadeIn } from "./animations/FadeIn";
import { ButtonLink } from "./Button";
import { Container } from "./Container";

/**
 *
 * @param {{title: string, subtitle: string, image: string, description: string}} props
 */

export function Hero(props) {
  console.log(props.video);
  return (
    <div className="relative h-screen overflow-hidden pt-10 pb-20 sm:py-48 sm:pb-48 bg-gray-800 flex justify-center items-center text-center">
      <div className="absolute w-full h-96 bg-gradient-to-b from-gray-900 inset-0 z-40 to-transparent" />
      <FadeIn transition={{ delay: 0.3, duration: 1 }}>
        <div className="absolute inset-0 overflow-hidden bg-gray-800">
          {/* <img
          src={props.image}
          className="h-full w-full object-cover mix-blend-overlay grayscale"
        /> */}
          <video
            className="absolute inset-0 z-10 min-h-full min-w-full object-cover opacity-100 mix-blend-soft-light"
            autoPlay
            loop
            muted
          >
            <source
              src="https://cdn.sanity.io/files/pr7dswz8/production/d556c118a6ad0d7d0669a9548e89bf7d5f1e0223.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <Container className="relative z-50">
          <div className="mx-auto max-w-2xl lg:max-w-4xl lg:px-12">
            <SlideUp transition={{ delay: 1, duration: 0.5 }}>
              <h1 className="font-body inline-block bg-gradient-to-r from-sky-300 to-white bg-clip-text text-5xl font-thin uppercase tracking-widest text-transparent sm:text-8xl">
                {props.title}
              </h1>
            </SlideUp>
            <SlideUp transition={{ delay: 1.125, duration: 0.5 }}>
              <div className="mt-6 space-y-6 text-lg tracking-normal text-white/80 font-medium sm:text-2xl">
                <p className="font-sans">{props.subtitle}</p>
              </div>
            </SlideUp>
            <SlideUp transition={{ delay: 1.25, duration: 0.5 }}>
              <div className="mt-6 space-y-6 text-lg tracking-normal text-white/80 font-thin sm:text-2xl">
                <p className="font-sans">{props.description}</p>
              </div>
            </SlideUp>

            <ButtonLink href="#" className="mt-10 w-full sm:hidden">
              Future of Colorado White Paper
            </ButtonLink>
          </div>
        </Container>
      </FadeIn>
    </div>
  );
}
